// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "Authors-module--aboutMainCircleBlurBlue--5a95b";
export var aboutMainCircleBlurGreen = "Authors-module--aboutMainCircleBlurGreen--86c22";
export var aboutMainCircleBlurGreenlower = "Authors-module--aboutMainCircleBlurGreenlower--ec77f";
export var aboutMainCircleBlurGreenupper = "Authors-module--aboutMainCircleBlurGreenupper--cf8de";
export var authorGithub = "Authors-module--authorGithub--af5ca";
export var authorLinkedIn = "Authors-module--authorLinkedIn--5e861";
export var authorPreviewImage = "Authors-module--authorPreviewImage--9de5f";
export var authorsBox = "Authors-module--authorsBox--1ef99";
export var authorsContainerWrapper = "Authors-module--authorsContainerWrapper--3b05e";
export var authorsMainArticles = "Authors-module--authorsMainArticles--145d3";
export var authorsMainContainer = "Authors-module--authorsMainContainer--b546b";
export var backgroundCircles = "Authors-module--backgroundCircles--6f81c";
export var backgroundCirclesBlogArticles = "Authors-module--backgroundCirclesBlogArticles--e9dfd";
export var blogsCircleBlueRight = "Authors-module--blogsCircleBlueRight--a83a2";
export var contactOutlineCircle = "Authors-module--contactOutlineCircle--b0624";